import { useState, useCallback } from 'react';

const useFilters = () => {
  const [filters, setFilters] = useState({ store_name: '', is_on_tr: '', is_new: '' });

  const handleFiltersChange = useCallback((event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  }, []);

  return { filters, handleFiltersChange };
};

export default useFilters;
