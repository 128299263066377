import React, { useState } from 'react';
import { Snackbar as MuiSnackbar, SnackbarContent, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const Snackbar = ({ message, open, onClose, autoHideDuration = 3000, action }) => {
  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={message}
      action={
        action || (
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        )
      }
    />
  );
};

export default Snackbar;
