import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useGetCrawledCuponsQuery, useRunGetCrawledCouponsMutation, useCancelGetCrawledCouponsMutation } from 'state/api';
import Header from 'components/Header';
import Snackbar from 'components/Snackbar';
import DataGridCustomTableClientSide from 'scenes/crawled_cupons/components/DataGridCustomTableClientSide';
import getColumns from './columns';
import AppBarWithFilters from '../components/AppBar';
import useFilteredData from '../components/FilteredData';
import { exportToCSV } from '../hooks/exportToCSV';
import useFilters from '../components/useFilters';
import useRunCancelCommand from '../components/useRunCancelCommand';

const CrawledCouponsList = ({ spider }) => {
  const [page, setPage] = useState(2);
  const [pageSize, setPageSize] = useState(20);
  const [selectedSpider, setSelectedSpider] = useState("cuponomia");
  const { data = [], isLoading } = useGetCrawledCuponsQuery({}, { skip: false });
  const [spiderOptions, setSpiderOptions] = useState([]);
  const [isFilterOn] = useState(true);
  const { filters, handleFiltersChange } = useFilters();
  const { isRunning, snackbarMessage, openSnackbar, handleRunCommand, handleCancel, setOpenSnackbar, } = useRunCancelCommand();

  useEffect(() => {
    if (Array.isArray(data)) {
      const uniqueSpiders = Array.from(new Set(data.map(item => item.spider))).sort();
      setSpiderOptions(uniqueSpiders);
    }
  }, [data]);

  const filteredData = useFilteredData(data, selectedSpider, filters);

  const handleSpiderChange = (event) => {
    setSelectedSpider(event.target.value);
  };  

  const handleExport = () => {
    exportToCSV(columns, filteredData);
  };

  const columns = getColumns(isFilterOn, filters, handleFiltersChange, spiderOptions, selectedSpider, handleSpiderChange);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem 1rem",
        height: "100vh",
      }}
    >
      <Header title="Todos Cupons do Crawler" subtitle="Tabela dos Cupons" />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        message={snackbarMessage}
        onClose={() => setOpenSnackbar(false)} 
      />
      <AppBarWithFilters
        selectedSpider={selectedSpider}
        spiderOptions={spiderOptions}
        handleSpiderChange={(event) => setSelectedSpider(event.target.value)}
        handleExport={handleExport}
        handleRunCommand={handleRunCommand}
        isRunning={isRunning}
        handleCancel={handleCancel}
      />
      <Box sx={{ flexGrow: 1 }}>
        <DataGridCustomTableClientSide
          data={filteredData}
          isLoading={isLoading}
          columns={columns}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          rowCount={filteredData.length}
          getRowId={(row) => row.id}
        />
      </Box>
    </Box>
  );
};

export default CrawledCouponsList;
