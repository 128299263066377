import { useState } from 'react';
import { useRunGetCrawledCouponsMutation, useCancelGetCrawledCouponsMutation } from 'state/api';

const useRunCancelCommand = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [runGetCrawledCoupons] = useRunGetCrawledCouponsMutation();
  const [cancelGetCrawledCoupons] = useCancelGetCrawledCouponsMutation();

  const handleRunCommand = async () => {
    setIsRunning(true);
    try {
      await runGetCrawledCoupons().unwrap();
      setSnackbarMessage('Comando executado com sucesso!');
    } catch (error) {
      setSnackbarMessage('Erro ao executar o comando.');
    } finally {
      setIsRunning(false);
      setOpenSnackbar(true);
    }
  };

  const handleCancel = async () => {
    setIsRunning(false);
    try {
      await cancelGetCrawledCoupons().unwrap();
      setSnackbarMessage('Cancelamento solicitado com sucesso!');
    } catch (error) {
      setSnackbarMessage('Erro ao solicitar cancelamento.');
    }
    setOpenSnackbar(true);
  };

  return { isRunning, snackbarMessage, openSnackbar, handleRunCommand, handleCancel, setOpenSnackbar, };
};

export default useRunCancelCommand;
