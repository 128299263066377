import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Button, Select, MenuItem, CircularProgress, } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { api_base_url } from "config";

const AppBarWithFilters = ({
  handleExport,
  handleCancel,
  handleRunCommand,
  selectedSpider,
  spiderOptions,
  handleSpiderChange,
  isRunning,
  showRunCommand = true,
}) => {

  const [zyteSpiders, setZyteSpiders] = useState([]);
  const [selectedZyteSpider, setSelectedZyteSpider] = useState("");
  const [loadingSpiders, setLoadingSpiders] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    const url = api_base_url + "/api/zyte/spiders/";
    const fetchZyteSpiders = async () => {
      setLoadingSpiders(true);
      try {
        const response = await fetch(url);
        const data = await response.json();
        setZyteSpiders(data.spiders || []);
      } catch (error) {
        console.error("Erro ao buscar spiders da Zyte:", error);
      } finally {
        setLoadingSpiders(false);
      }
    };

    fetchZyteSpiders();
  }, []);

  const handleFetchAndStoreZyteData = async () => {
    if (!selectedZyteSpider) {
      alert("Selecione um spider para buscar os dados!");
      return;
    }

    setLoadingData(true);
    try {
      const url = api_base_url + "/api/zyte/spiders/store/";
      const response = await fetch(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${btoa("717270ba06ec4fbe91b05688ca93cf62:")}`,
          },
          body: new URLSearchParams({ spider: selectedZyteSpider }).toString(),
        }
      );

      const data = await response.json();
      console.log("Resultado da Armazenagem:", data);
      alert(`Dados armazenados com sucesso! Criados: ${data.created}, Atualizados: ${data.updated}`);
    } catch (error) {
      console.error("Erro ao armazenar dados:", error);
    } finally {
      setLoadingData(false);
    }
  };


  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          onClick={handleExport}
          sx={{ ml: 2 }}
        >
          Exportar CSV
        </Button>

        {showRunCommand && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRunCommand}
              disabled={isRunning}
              sx={{ ml: 2 }}
            >
              {isRunning ? "Carregando..." : "Atualizar Todos Crawlers"}
            </Button>

            {isRunning && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                sx={{ ml: 2 }}
              >
                Cancelar
              </Button>
            )}
          </>
        )}

        <Select
          value={selectedSpider}
          onChange={handleSpiderChange}
          displayEmpty
          sx={{
            ml: 4,
            color: "inherit",
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "35px",
            "& .MuiSvgIcon-root": {
              color: "inherit",
            },
            fontSize: "0.875rem",
          }}
        >
          <MenuItem value="">
            <em>Selecionar Spider para Filtrar a Tabela</em>
          </MenuItem>
          {spiderOptions.map((spider) => (
            <MenuItem key={spider} value={spider}>
              {spider}
            </MenuItem>
          ))}
        </Select>

        {/* Select para buscar os dados do Spider na Zyte */}
        <Select
          value={selectedZyteSpider}
          onChange={(e) => setSelectedZyteSpider(e.target.value)}
          displayEmpty
          sx={{
            ml: 4,
            color: "inherit",
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "35px",
            "& .MuiSvgIcon-root": {
              color: "inherit",
            },
            fontSize: "0.875rem",
          }}
        >
          <MenuItem value="">
            <em>Selecionar Spider para Buscar Dados- ZYTE</em>
          </MenuItem>
          {loadingSpiders ? (
            <MenuItem disabled>
              <CircularProgress size={20} />
            </MenuItem>
          ) : (
            zyteSpiders.map((spider) => (
              <MenuItem key={spider.id} value={spider.id}>
                {spider.id}
              </MenuItem>
            ))
          )}
        </Select>

        {/* Botão para Buscar os Dados do Spider Selecionado */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleFetchAndStoreZyteData}
          disabled={loadingData}
          sx={{ ml: 2 }}
        >
          {loadingData ? "Buscando..." : "Buscar Dados"}
        </Button>

      </Toolbar>
    </AppBar>
  );
};


export default AppBarWithFilters;
